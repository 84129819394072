.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .App-header {
    padding: 0;
  }

  .img-width {
    width: 100%;
  }

  .sigchart-width {
    width: 100%;
  }

  .gaf-width {
    width: 100%;
  }
  .Suncalc,
  .Time {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .App-header {
    padding: 0 1%;
  }

  .img-width {
    width: 100%;
  }

  .sigchart-width {
    width: 100%;
  }

  .gaf-width {
    width: 100%;
  }
  .Suncalc,
  .Time {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .App-header {
    padding: 0 1%;
  }

  .img-width {
    width: 80%;
  }

  .sigchart-width {
    width: 100%;
  }

  .gaf-width {
    width: 100%;
  }
  .Suncalc,
  .Time {
    display: flex;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .App-header {
    padding: 0 1%;
  }

  .img-width {
    width: 80%;
  }

  .sigchart-width {
    width: 80%;
  }

  .gaf-width {
    width: 100%;
  }
  .Suncalc,
  .Time {
    display: flex;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .App-header {
    padding: 0 0%;
  }

  .img-width {
    width: 60%;
  }

  .sigchart-width {
    width: 80%;
  }

  .gaf-width {
    width: 80%;
  }
  .Suncalc,
  .Time {
    display: flex;
  }
}

/* Extra large devices (large laptops and desktops, 1800px and up) */
@media only screen and (min-width: 1800px) {
  .App-header {
    padding: 0 0%;
  }

  .img-width {
    width: 50%;
  }

  .sigchart-width {
    width: 80%;
  }

  .gaf-width {
    width: 80%;
  }
  .Suncalc,
  .Time {
    display: flex;
  }
}

.Suncalc {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-weight: bold;
  font-size: small;
  margin-left: 20px;
}

.Link {
  color: white;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  margin-left: 14px;
}

.Link:hover {
  border-bottom: 2px solid white;
}

.Icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid transparent;
  margin-left: 14px;
}

.Icon:hover {
  border-bottom: 2px solid white;
}

.Base-menu {
  text-align: left;
  margin-left: 10px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.Base-menu:hover {
  border-bottom: 2px solid white;
}

.Base-menu-items {
  cursor: pointer;
  font-weight: bold;
  margin: 0;
  margin-bottom: 12px;
  padding: 0;
  font-size: larger;
  border-bottom: 2px solid transparent;
}

.Base-menu-items:hover {
  border-bottom: 2px solid white;
}

.Time {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App-header {
  background-color: #000;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
